import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
      1992年神奈川県生まれ、鹿児島出身。<br />
      2016年青山学院大学法学部卒業後、モンタージュ入社。<br />
      大型展示映像やWebプロモーション映像などの制作を経て、2020年より現職。<br />
      プロジェクトに携わるすべての人が幸せになれる作品づくりを心がけています。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
